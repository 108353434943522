@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");


h_price {
  text-align: center;
  margin-top: 2rem;
  color: #9340FF;
  font-size: 30px;
}

p {
  text-align: center;
  margin-bottom: 4rem;
}

.pricing {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .plan {
    background-color: #1f1f1f;
    ;
    padding: 2.5rem;
    margin: 15px;
    border-radius: 5px;
    text-align: center;
    transition: 0.3s;
    cursor: pointer;

    h2 {
      font-size: 22px;
      margin-bottom: 12px;
    }

    .price {
      margin-bottom: 1rem;
      font-size: 25px;
      color: #fff;
    }

    ul.features {
      list-style-type: none;
      text-align: left;

      li {
        margin-bottom: 8px;
        margin-top: 8px;
        margin-right: -20px;
        margin-left: -20px;
        color: rgb(204, 204, 204);

        .fas {
          margin-right: 4px;
        }

        .fa-check-circle {
          color: #6ab04c;
        }

        .fa-times-circle {
          color: #eb4d4b;
        }
      }
    }

    button {
      border: none;
      width: 100%;
      padding: 12px 35px;
      margin-top: 1rem;
      background-color: #6ab04c;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
    }

    &.popular {
      border: 4px solid #9340FF;
      position: relative;
      transform: scale(1.08);
      margin: 28px;

      span {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #9340FF;
        color: #fff;
        padding: 4px 20px;
        font-size: 18px;
        border-radius: 5px;
      }
    }

    &:hover {
      box-shadow: 5px 7px 67px -28px rgba(0, 0, 0, 0.37);
    }
  }
}