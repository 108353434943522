/* src/components/Navigation.css */
.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #101010;
  color: white;
  z-index: 1000;
  padding: 20px;
  transition: background-color 0.5s ease;
  height: 100px;
}

.navigation .logo img {
  max-height: 50px;
  margin-top: 15px;
}

.menu-icon {
  display: none;
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  padding-right: 50px;
  font-size: 1.8vw;
  margin-top: 15px;
}

.nav-links li {
  margin-right: 20px;
}

.nav-links a {
  color: #cccccb;
  text-decoration: none;
  cursor: pointer;
}

.right-section {
  width: 400px;
  height: 400px;
}

/* Responsive adjustments for mobile view */
@media (max-width: 768px) {
  .right-section {
    height: 420px;
  }

  .menu-icon {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-right: 10px;
  }

  .hamburger-icon {
    font-size: 40px;
    margin-top: -35px;
  }

  .nav-links {
    color: white;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 70px;
    right: 0;
    background-color: #101010;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease;
    padding-left: 30px;
    font-size: 30px;
    margin-top: -10px;
  }

  .nav-links.open {
    margin-top: -10px;
    max-height: 300px;
    padding-bottom: 20px;
  }

  .navigation .logo img {
    padding-top: 10px;
    max-height: 40px;
  }

  .nav-links li {
    margin-right: 0;
  }
}

.lang-menu {
  position: relative;
  cursor: pointer;
  margin-left: 20px;
}

.selected-lang {
  font-size: 20px; /* Zmniejszony rozmiar czcionki dla wybranego języka */
}

.lang-options {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #101010;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  z-index: 1001;
}

.lang-options li {
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
}

.lang-options li:hover {
  background-color: #333;
}

@media (max-width: 768px) {
  .lang-options {
    top: 0;
    display: flex;
    flex-direction: row; /* Ustawienie kierunku wiersza dla rozwijania w prawo */
    border: none; /* Usunięcie obramowania dla mobilnych */
    border: 1px solid #ccc; /* Dodanie obramowania dla elementów listy */
    
  }
  
  .lang-options li {
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    background-color: #101010; /* Ustawienie tła dla elementów listy */
  }
}
