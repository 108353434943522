* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  max-width: 100vw;
  /* Limit width to viewport width */
  overflow-x: hidden;
  /* Hide horizontal overflow */
}

body {
  font-family: Arial, sans-serif;
  background-color: #101010;
  color: white;
  line-height: 1.6;
}

a {
  color: #394867;
  text-decoration: none;
}

button {
  background-color: #212A3E;
  color: #F1F6F9;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #394867;
}

.container {
  width: 80%;
  margin: auto;
  max-width: 1200px;
}

h1 {
  font-size: 50px;
  color: #212A3E;
}

h2 {
  font-size: 2rem;
  color: #394867;
}

p {
  margin: 0.5rem 0;
}

.section-separator {
  font-size: 270px;
  color: #1f1f1f;
  text-align: center;
  font-weight: 650;
  margin: 0px 10px 50px 10px
}

@media (max-width: 1550px) {
  .section-separator {
    font-size: 230px;
    
  }
}


@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  .section-separator {
    font-size: 60px;
    color: #1d1d1d;
    text-align: center;
    font-weight: 650;
    margin: 50px 10px 50px 10px;
    
    transform: scaleY(1.3); /* Zmieniaj wartość aby dostosować poziom rozciągnięcia */
}


  h2 {
    font-size: 1.5rem;
  }

  .container {
    width: 90%;
  }
}