/* Style dla sekcji nagłówka */
.hero-section {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  height: 800px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  background-color: #101010;
  border-radius: 30px;
  padding: 30px;
  margin-left: 50px;
  margin-right: 50px;
  overflow: hidden;
}

.right-section {

  margin-right: 100px;
  justify-content: center;
  align-items: center;
  height: 700px;
  width: 700px;
  overflow: hidden;
  min-width: 700px;

  /* =-=-=-=-=-=-=-=-=-= Do usunięcia przy modelu 3d =-=-=-=-=-=-=-*/


}
.image-section-hero{
  scale:70%;
}

.hero-text {
  padding-left: 100px;
  
}

.hero-text h1,
.hero-text h2 {
  font-family: 'Roboto', sans-serif;
  color: white;
  text-align: left;
  margin-right: 0px;
  max-width: 100%;
}
.image-section-hero{
  border-radius: 50px;
}

.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(-90deg,
      #4D4855 25%,
      #A399B2 40%,
      #fff 50%,
      #fff 70%,
      #A399B2 80%,
      #4D4855 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 5s linear infinite;
  display: inline-block;
  font-size: 65px;
  
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.hero-text h1 {
  font-size: 4vw;
  font-weight: 700;
  padding-right: 0px;
}

.hero-text h2 {
  font-size: 50px;
  font-weight: 300;
}

.hero-text h3 {
  font-size: 1vw;
  font-weight: 300;
  max-width: 700px;
  text-align: justify;
  color: #cccccb;
}

.hero-3d-model {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-3d-model img {
  height: auto;
  margin-left: 100px;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  object-fit: cover;
}

/* Style dla przycisku CTA */
.button-container {
  display: flex;
  justify-content: flex-start;
  /* Wyrównanie do lewej */
  gap: 20px;
  /* Odstęp między przyciskami */
  margin-top: 20px;
}

.cta-button,
.cta-button-reverse {
  background: linear-gradient(to right, #9340FF, #FF3C5F, #FF3C5F, #FBB03B);
  text-align: center;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  height: 55px;
  border: none;
  background-size: 300% 100%;
  box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75);
  transition: all .4s ease-in-out;
  width: 230px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}



.cta-button a,
.cta-button-reverse a,
.cta-button3 a {
  color: #0F1819;
  font-size: 20px;
}



/* Wyrównanie tekstu w buttonie na środek */
.cta-button:hover,
.cta-button-reverse:hover,
.cta-button3:hover {
  background-position: 100% 0;
  transition: all .4s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media (max-width: 1400px) {
  .right-section {
    margin-right: 0px;
  }
}

@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    height: auto;
    padding: 0rem;
    margin: 20px;
    padding-top: 50px;
    border-radius: none;
    border-style: none;
    background-color: #101010;
  }

  .right-section {
    display: flex;
    margin-top: 20px;
    height: 400px;
    width: 100%;
    margin: 0;
  }

  .hero-text {
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .hero-text h1,
  .hero-text h2 {
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 10vw;
  }
  .hero-text h3{
    font-size: 16px;
  }

  .hero-3d-model img {
    margin-top: 80px;
    width: 100%;
    height: auto;
  }

  .button-container {
    justify-content: center;
    /* Center the buttons on mobile */
    width: 95%;
    position: static;
    /* Reset position for mobile */
  }

  .cta-button {
    justify-content: center;
    /* Center the buttons on mobile */
    width: 100%;
    position: static;
    /* Reset position for mobile */
  }


}

.hero-3d-model img {
  margin-top: 80px;
  margin-left: 0;
}

.hero-text {
  align-items: center;
  text-align: center;
}