.contact-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 150px;
}

.form-container {
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 500px;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.input-group input,
.input-group select,
.input-group textarea {
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 10px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.input-group textarea {
  height: 150px;
  resize: vertical;
}

.input-group input::placeholder,
.input-group select::placeholder,
.input-group textarea::placeholder {
  font-size: 1rem;
  color: #101010;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  opacity: 1;
}

.checkbox-group {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: flex-start;
  margin-top: 10px;
}

.checkbox-group input[type="checkbox"] {
  margin-right: -10px;
  transform: scale(1.5);
  cursor: pointer;
  margin-left: -200px;
}

.checkbox-group label {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: #495057;
  cursor: pointer;
  margin: 0;
  margin-left: -200px;
}

.cta-button4 {
  background: linear-gradient(to right, #9340FF, #FF3C5F, #FF3C5F, #FBB03B);
  text-align: center;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  height: 55px;
  border: none;
  background-size: 300% 100%;
  box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75);
  transition: all .4s ease-in-out;
  width: 230px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

button[type="submit"] {
  font-family: 'Roboto', sans-serif;
  color: #0F1819;
  font-weight: 600;
  background-color: transparent;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 10px;
  cursor: pointer;
  display: block;
  width: 100%;
  margin-top: 1rem;
  padding-bottom: 20px;
  transition: all 0.2s;
  font-size: 20px;
  font-weight: 600;
}

button[type="submit"]:hover {
  background-color: #0056b3;
  border-color: #004085;
  background-color: transparent;
}

.gradient-div-contact {
  background: linear-gradient(90deg, rgba(16, 16, 16, 1) 10%, rgba(147, 64, 255, 0.35) 100%);
  padding: 25px;
  border-radius: 20px;
  margin-top: 10px;
  max-width: 50%;
  text-align: center;
}

.section-text-contact {
  font-family: 'Roboto', sans-serif;
  font-size: 45px;
  color: #fff;
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
  }
  .checkbox-group input[type="checkbox"] {
    margin-right: -120px;
    margin-left: 90px;
    padding-left: 0px;
    padding-bottom: 0px;
  }

  .gradient-div-contact,
  .form-container {
    max-width: 90%;
    padding: 15px;
  }

  .section-text-contact {
    font-size: 20px;
  }

  .gradient-div-contact {
    order: -1;
    /* Move text section to the top */
  }
}
