.footer {
  background-color: #0b0c10;
  /* Tło stopki */
  color: white;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 300px;
  /* Zwiększenie paddingu na górze */

}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
}

.footer-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* Wyrównaj logo do lewej */
}

.footer-logo-link {
  display: flex;
  align-items: center;
}

.footer-logo {
  max-height: 60px;
  /* Zmniejsz logo */
}

.footer-right {
  text-align: left;
  margin-left: 40px;
  /* Oddal elementy od siebie */
}

.footer-right h6 {
  margin: 0;
  font-size: 50px;
  /* Większy rozmiar tekstu */
  color: #9340FF;
  /* Kolor tekstu */
  font-weight: bold;
}

.footer-right p {
  margin: 10px 0;
  font-size: 25px;
  line-height: 1.5;
  text-align: left;
}

.social-icons {
  margin-top: 100px;
  /* Oddal ikonki od tekstu */
}

.social-icons a {
  color: white;
  margin-left: 15px;
  font-size: 40px;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #9340FF;
  /* Kolor przy hover */
}

.footer-bottom {
  margin-top: 300px;
  text-align: center;
  font-size: 14px;
  color: #aaaaaa;
}

/* Styl dla widoku mobilnego */
@media (max-width: 768px) {
  .footer {
    padding-top: 150px;
    /* Zwiększenie paddingu na górze */
    margin-bottom: 0px;
    padding-bottom: 0px;

  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* Wyśrodkowanie tekstu */
  }

  .footer-right {
    margin-left: 0;
    /* Usunięcie marginesu */
    margin-top: 20px;
    /* Dodanie marginesu na górze */
  }

  .footer-logo {
    max-height: 40px;
    /* Zmniejsz logo */
    margin-bottom: 50px;
  }

  .footer-right h6 {
    font-size: 28px;
    /* Zmniejszenie rozmiaru tekstu */
  }

  .footer-right p {
    font-size: 20px;
    /* Zmniejszenie rozmiaru tekstu */
  }

  .social-icons {
    margin-top: 40px;
    /* Zmniejszenie marginesu na górze */
  }

  .social-icons a {
    font-size: 30px;
    /* Zmniejszenie rozmiaru ikon */
    margin-left: 10px;
    /* Zmniejszenie marginesu między ikonami */
  }

  .footer-bottom {
    margin-top: 230px;
    /* Zmniejszenie marginesu na górze */

  }
}