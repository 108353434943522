/* Keyframes */
@-webkit-keyframes init-emmusz {
  0% {
    width: 0px;
    height: 0px;
  }

  100% {
    width: 56px;
    height: 56px;
    margin-top: 0px;
    opacity: 1;
  }
}

@keyframes init-emmusz {
  0% {
    width: 0px;
    height: 0px;
  }

  100% {
    width: 56px;
    height: 56px;
    margin-top: 0px;
    opacity: 1;
  }
}

@-webkit-keyframes puff-emmusz {
  0% {
    top: 100%;
    height: 0px;
    padding: 0px;
  }

  100% {
    top: 50%;
    height: 100%;
    padding: 0px 100%;
  }
}

@keyframes puff-emmusz {
  0% {
    top: 100%;
    height: 0px;
    padding: 0px;
  }

  100% {
    top: 50%;
    height: 100%;
    padding: 0px 100%;
  }
}

@-webkit-keyframes borderRadius-emmusz {
  0% {
    -webkit-border-radius: 50%;
  }

  100% {
    -webkit-border-radius: 0px;
  }
}

@keyframes borderRadius-emmusz {
  0% {
    -webkit-border-radius: 50%;
  }

  100% {
    border-radius: 0px;
  }
}

@-webkit-keyframes moveDown-emmusz {
  0% {
    top: 50%;
  }

  50% {
    top: 40%;
  }

  100% {
    top: 100%;
  }
}

@keyframes moveDown-emmusz {
  0% {
    top: 50%;
  }

  50% {
    top: 40%;
  }

  100% {
    top: 100%;
  }
}

@-webkit-keyframes moveUp-emmusz {
  0% {
    background: #FFB300;
    top: 100%;
  }

  50% {
    top: 40%;
  }

  100% {
    top: 50%;
    background: #E0E0E0;
  }
}

@keyframes moveUp-emmusz {
  0% {
    background: #FFB300;
    top: 100%;
  }

  50% {
    top: 40%;
  }

  100% {
    top: 50%;
    background: #E0E0E0;
  }
}

@-webkit-keyframes materia-emmusz {
  0% {
    background: #E0E0E0;
  }

  50% {
    -webkit-border-radius: 4px;
  }

  100% {
    width: 400px;
    height: 600px;
    background: #FFFFFF;
    -webkit-border-radius: 4px;
  }
}

@keyframes materia-emmusz {
  0% {
    background: #E0E0E0;
  }

  50% {
    border-radius: 4px;
  }

  100% {
    width: 400px;
    height: 660px;
    background: #FFFFFF;
    border-radius: 4px;
  }

}
@media screen and (max-width: 800px) {
  @keyframes materia-emmusz {
    0% {
      background: #E0E0E0;
    }
  
    50% {
      border-radius: 4px;
    }
  
    100% {
      margin-top: 25px;
      width: 90%;
      height: 85%;
      background: #FFFFFF;
      border-radius: 4px;
    }
  
  }
}

@-webkit-keyframes moveIn-emmusz {
  0% {
    margin-top: 50px;
    opacity: 0;
  }

  100% {
    opacity: 1;
    margin-top: -20px;
  }
}

@keyframes moveIn-emmusz {
  0% {
    margin-top: 50px;
    opacity: 0;
  }

  100% {
    opacity: 1;
    margin-top: -20px;
  }
}

@-webkit-keyframes scaleIn-emmusz {
  0% {
    -webkit-transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes scaleIn-emmusz {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes ripple-emmusz {
  0% {
    transform: scale3d(0, 0, 0);
  }

  50%,
  100% {
    -webkit-transform: scale3d(1, 1, 1);
  }

  100% {
    opacity: 0;
  }
}

@keyframes ripple-emmusz {
  0% {
    transform: scale3d(0, 0, 0);
  }

  50%,
  100% {
    transform: scale3d(1, 1, 1);
  }

  100% {
    opacity: 0;
  }
}
/* Animation */
.animation-emmusz {
  overflow: hidden;
  background-size: cover;
  position: fixed;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
}

.animation-emmusz:before {
  content: "";
  height: 0px;
  padding: 0px;
  border: 300em solid #F0ECE5;
  position: absolute;
  left: 50%;
  top: 100%;
  z-index: 2;
  display: block;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation: puff-emmusz 0.5s 1.8s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards, borderRadius-emmusz 0.2s 2.3s linear forwards;
  animation: puff-emmusz 0.5s 1.8s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards, borderRadius-emmusz 0.2s 2.3s linear forwards;
}

/* Headers */
.h1-emmusz,
.h2-emmusz {
  font-weight: 500;
  margin: 0px 0px 5px 0px;
}

.h1-emmusz {
  font-size: 30px;
}

.h2-emmusz {
  font-size: 16px;
}

/* Paragraph */
p {
  margin: 0px;
}

/* Profile Card */
.profile-card-emmusz {
  background: #FFB300;
  width: 56px;
  height: 56px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  overflow: hidden;
  opacity: 0;
  margin-top: 70px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
  -webkit-animation: init-emmusz 0.5s 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards, moveDown-emmusz 1s 0.8s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards, moveUp-emmusz 1s 1.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards, materia-emmusz 0.5s 2.7s cubic-bezier(0.86, 0, 0.07, 1) forwards;
  animation: init-emmusz 0.5s 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards, moveDown-emmusz 1s 0.8s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards, moveUp-emmusz 1s 1.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards, materia-emmusz 0.5s 2.7s cubic-bezier(0.86, 0, 0.07, 1) forwards;
}

.profile-card-emmusz header {
  width: 179px;
  height: 150px;
  padding: 40px 20px 30px 20px;
  display: inline-block;
  border-right: 2px dashed #EEEEEE;
  background: #FFFFFF;
  color: #000000;
  margin-top: 50px;
  opacity: 0;
  text-align: center;
  -webkit-animation: moveIn-emmusz 1s 3.1s ease forwards;
  animation: moveIn-emmusz 1s 3.1s ease forwards;
  margin-bottom: -30px;
  
}

.profile-card-emmusz header h1 {
  color: #1800ec;
  font-size: 24px;
}

.profile-card-emmusz header h2 {
  color: #1800ec;
  font-size: px;
}

.profile-card-emmusz header a {
  display: inline-block;
  text-align: center;
  position: relative;
  margin: 25px 30px;
}

.profile-card-emmusz header a:after {
  position: absolute;
  content: "";
  bottom: 3px;
  right: 30px;
  width: 20px;
  height: 20px;
  border: 4px solid rgb(237, 237, 237);
  -webkit-transform: scale(0);
  transform: scale(0);
  background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 50%, #ff0707 50%, #ff0000 100%);
  background: linear-gradient(#ffffff 0%, #ffffff 50%, #ff0707 50%, #ff0707 100%);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  -webkit-animation: scaleIn-emmusz 0.3s 3.5s ease forwards;
  animation: scaleIn-emmusz 0.3s 3.5s ease forwards;
}

.profile-card-emmusz header a>img {
  width: 200px;
  max-width: 100%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: -webkit-box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -webkit-box-shadow: 0px 0px 0px 8px rgb(0, 0, 0, 0.06);
  box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.06);
}

.profile-card-emmusz header a:hover>img {
  -webkit-box-shadow: 0px 0px 0px 16px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 0px 16px rgba(0, 0, 0, 0.12);
}

.profile-card-emmusz .profile-bio-emmusz {
  width: 175px;
  height: 180px;
  display: inline-block;
  padding: 50px 20px 30px 20px;
  background: #ffffff;
  color: #333333;
  margin-top: 20px;
  text-align: left;
  opacity: 0;
  -webkit-animation: moveIn-emmusz 1s 3.1s ease forwards;
  animation: moveIn-emmusz 1s 3.1s ease forwards;
  font-size: 12px;
}

.profile-card-emmusz .bio-text1-emmusz {
  color: black;
  line-height: 1.6;
  margin-top: -20px;
}

.profile-card-emmusz .bio-text2-emmusz {
  color: black;
  font-weight: bold;
  line-height: 1.6;
}

/* Profile Social Links */
.profile-social-links-emmusz {
  width: 218px;
  display: inline-block;
  margin: 0px;
  padding: 15px 20px;
  background: white;
  margin-top: 50px;
  text-align: center;
  opacity: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-animation: moveIn-emmusz 1s 3.1s ease forwards;
  animation: moveIn-emmusz 1s 3.1s ease forwards;
  margin-bottom: 30px;
}

.profile-social-links-emmusz li {
  list-style: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  float: left;
  width: 25%;
  text-align: center;
  height: auto;
}

.profile-social-links-emmusz li a {
  display: inline-block;
  color: white;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden !important;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.profile-social-links-emmusz li a i {
  text-align: center;
  position: relative;
  font-size: 20px;
  color: #1800ec;
  z-index: 1;
}

.profile-social-links-emmusz li a img,
.profile-social-links-emmusz li a svg {
  width: 24px;
}

/* Navigation */
.nav-emmusz {
  background: rgba(255, 255, 255, 0.0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0px;
  margin-top: -20px;
  margin-right: 30px;
}

.nav-emmusz .nav-link-emmusz {
  width: 100px;
  z-index: 2000;
}

.nav-emmusz .nav-link-emmusz img {
  padding-top: 35px;
  max-width: 250px;
  padding-left: 30px;
}

.nav-emmusz .logo-text1-emmusz {
  color: white;
  margin-left: -20px;
  line-height: 1.2;
  font-size: 14px;
}

.nav-emmusz ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-emmusz ul li {
  margin-left: 20px;
}

.nav-emmusz ul li a {
  text-decoration: none;
  color: #ffffff;
}


/* Language Slider */
.language-slider-emmusz {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  margin-top: 30px;
}



.lang-menu2-emmusz {
  position: relative;
  cursor: pointer;
  font-size: 16px;
}

.selected-lang2-emmusz {
  display: inline-block;
  margin-right: 10px;
}

.lang-options2-emmusz {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #101010;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  z-index: 1001;
}

.lang-options2-emmusz li {
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
}

.lang-options2-emmusz li:hover {
  background-color: #333;
}





/* Media Queries */
@media screen and (min-aspect-ratio: 4/3) {
  
  body {
    background-size: cover;
  }

  body:before {
    width: 0px;
  }

  @-webkit-keyframes puff-emmusz {
    0% {
      top: 100%;
      width: 0px;
      padding-bottom: 0px;
    }

    100% {
      top: 50%;
      width: 100%;
      padding-bottom: 100%;
    }
  }

  @keyframes puff-emmusz {
    0% {
      top: 100%;
      width: 0px;
      padding-bottom: 0px;
    }

    100% {
      top: 50%;
      width: 100%;
      padding-bottom: 100%;
    }
  }

  .profile-card-emmusz header {
    width: auto;
    height: auto;
    padding: 30px 20px;
    display: block;
    float: none;
    border-right: none;
    margin-bottom: -40px;
  }

  .profile-card-emmusz .profile-bio-emmusz {
    width: auto;
    height: auto;
    padding: 15px 20px 30px 20px;
    display: block;
    float: none;
  }

  .profile-social-links-emmusz {
    width: 100%;
    display: block;
    float: none;
    
  }
}

@media screen and (max-width: 800px) {
  
  .profile-card-emmusz header {
    width: auto;
    height: auto;
    padding: 30px 20px;
    display: block;
    float: none;
    border-right: none;
  }
  .profile-card-emmusz .profile-bio-emmusz {
    width: auto;
    height: auto;
    padding: 5px 20px 20px 20px;
    display: block;
    float: none;
    
  }
  

  .profile-social-links-emmusz {
    width: 100%;
    display: block;
    float: none;
    margin-bottom: 32px;
    
  }
  .profile-card-emmusz header h1 {
    color: #1800ec;
    font-size: 5vw;
    margin-top: -10px;
    margin-bottom: 0px;
  }
  
  .profile-card-emmusz header h2 {
    color: #1800ec;
    font-size: 4vw;
    margin-bottom: 2px;
  }
  .profile-card-emmusz .profile-bio-emmusz {
    text-align: justify;
    font-size: 3vw;
   
  }
  .profile-card-emmusz header a>img {
    width: 150px;
  }
  .download-vcard-button-emmusz {
    width: 200px;
    height: 30px;
  }
  .profile-social-links-emmusz {
    margin-bottom: 10px;
  }
}


/* Button */
.download-vcard-button-emmusz {
  background-color: #1800eca6;
  border: none;
  color: white;
  font-weight: 700;

  
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px auto;
  
  cursor: pointer;
  border-radius: 25px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: flex; /* Use flexbox */
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
  
}

.download-vcard-button-emmusz:hover {
  background-color: #9c31f3; /* Darker color on hover */
  transform: scale(1.05); /* Slightly larger on hover */
}

.download-vcard-button-emmusz:active {
  background-color: #1800ec; /* Even darker color on click */
  transform: scale(0.95); /* Slightly smaller on click */
}

.download-vcard-button-emmusz:focus {
  outline: none; /* Remove outline on focus */
}

/* Android padding */
.android-padding {
  padding-top: 30px;
}

