.about-us {
  text-align: center;
  padding: 20px;
  margin-top: -50px;
  margin-left: 100px;
  margin-right: 100px;
}

.section-header {
  background-color: #101010;
  color: white;
  padding: 20px;
  margin-bottom: 10px;
  font-size: 48px;
  max-width: 40%;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
  /* Start hidden */
  transform: translateX(-50px);
  /* Start off-screen */
}

.header-highlight {

  color: #9340FF;
  display: inline-block;
}

.gradient-div {
  background: linear-gradient(90deg, rgba(16, 16, 16, 1) 10%, rgba(147, 64, 255, 0.35) 100%);
  padding: 15px;
  border-radius: 30px;
  margin-top: 10px;
  max-width: 55%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  opacity: 0;
  /* Start hidden */
  transform: translateX(50px);
  /* Start off-screen */

}

.section-text {
  font-family: 'Roboto', sans-serif;

  font-size: 24px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;

}

/* Animations */
@keyframes slideFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideFromRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(50px);
  }
}

.slide-from-left {
  animation: slideFromLeft 0.7s ease forwards;
}

.slide-from-right {
  animation: slideFromRight 0.7s ease forwards;
  animation-delay: 0.5;
}

.fade-out {
  animation: fadeOut 0.7s ease forwards;
}

@media (max-width: 1400px) {
  .gradient-div {
    max-width: 70%;
  }

  .section-header {

    max-width: 60%;

  }
}

/* Responsive Design */
@media (max-width: 600px) {
  .about-us {
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section-header,
  .gradient-div,
  .section-text {
    max-width: 90%;
  }

  .section-header {
    font-size: 30px;
    padding: 15px;
  }

  .gradient-div {
    padding: 15px;
    margin-right: 30px;
    margin-left: 30px;
  }

  .section-text {
    font-size: 13.5px;
    text-align: center;
    font-weight: 600;
    max-width: 100%;
  }
}