.cards-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  padding-top: 0px;
  margin-top: 0px;
  margin-right: 20px;
  margin-left: 20px;
  max-width: 20%, 80%;

}

.why-us-header h2 {
  text-align: center;
  color: white;
  font-size: 42px;
  /* Adjust size as needed */
  margin-bottom: 20px;
  /* Adjust spacing as needed */
  margin-top: 100px;
  max-width: 100%;
}


@media (min-width: 768px) {
  .cards-container {
    flex-direction: row;
    justify-content: center;
    
  }

  .why-us-header h2 {
    margin-top: 300px;
    font-size: 65px;
  }
}

.card {
  font-weight: bold;
  background: linear-gradient(120deg, rgba(147, 64, 255, 0.8), rgba(255, 60, 95, 0.7));
  padding: 0px;
  text-align: right;
  color: #181a1a;
  height: 400px;
  box-shadow: 0 1px 5px #00000099;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  perspective: 1000px;
  margin-top: 50px;
  max-width: 300px;
  width: 90%;
  /* Full width of the container */
}

.card-image {
  width: 100%;
  height: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  padding: 1em;
  text-align: left;
}

.card-content h5 {
  margin-top: 0;
  text-align: center;
  font-size: 24px;
  vertical-align: top;
}

.card-content p {
  margin-bottom: 0;
  font-size: 12px;
}