.section-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.products-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 150px;
  margin-left: 150px;
  margin-right: 150px;
}

.text-section {
  flex: 1;
  margin: 0px;
  background-color: transparent;
  text-align: justify;
  padding-left: 10%;
  padding-right: 10%;
}

.text-section-reverse {
  flex: 1;
  margin: 50px;
  margin-right: 50px;
  background-color: transparent;
  text-align: justify;
  position: relative;
  /* Ensure position context for absolute button alignment */
}

.image-section {
  flex: 1;
  height: 450px;
  /* Increased height */
  display: flex;
  justify-content: center;
}
.video-section {
  flex: 1;
  height: 450px;
  /* Increased height */
  display: flex;
  justify-content: center;
}

.text-section h1,
.text-section-reverse h1 {
  font-size: 55px;
  font-weight: 800;
  color: #9340FF;
}

.text-section h2,
.text-section-reverse h2 {
  color: white;
  font-size: 25px;
}

.image-section img {
  max-width: 100%;
  height: auto;
}

.cta-button,
.cta-button-reverse {
  margin-top: 20px;
  
  display: inline-block;
  /* Ensure button does not stretch */
}

.cta-button {
  justify-content: flex-start;
  /* Aligns button to the left */
}

.cta-button-reverse {
  justify-content: flex-end;
  /* Aligns button to the right */
  position: absolute;
  right: 170px;
}

.products-container.reverse {
  flex-direction: row-reverse;
}

.text-section,
.image-section {
  flex: 1;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .video-section {
    max-width: 250px;
    margin-bottom: 30px;
    
  }

  .products-container,
  .products-container.reverse {
    flex-direction: column;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 50px;
  }

  .text-section,
  .text-section-reverse {
    flex: 1;
    margin: 0 auto;
    background-color: transparent;
    order: 1;
    /* Ensures the main text comes first */
    position: static;
    /* Reset position for mobile */
    padding-left: 10px;
    padding-right: 10px;
  }

  .text-section h1,
  .text-section-reverse h1 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }

  .text-section h2,
  .text-section-reverse h2 {
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
  }

  .image-section {
    margin-bottom: 30px;
    height: 350px;
    /* Adjusted for mobile */
  }

  .cta-button,
  .cta-button-reverse {
    justify-content: center;
    /* Center the buttons on mobile */
    width: 100%;
    position: static;
    /* Reset position for mobile */
  }

  .cta-button a,
  .cta-button-reverse a {
    width: auto;
    /* Ensure link inside button does not stretch */
  }
}